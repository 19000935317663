import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const MenuItem = ({ title, withDropdown, dropdownOptions }) => {
  const handleClick = (e) => {
    if (title === "Download App") {
      e.preventDefault();
      const downloadSection = document.getElementById("download-app");
      if (downloadSection) {
        downloadSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  if (withDropdown && dropdownOptions?.length > 0) {
    return (
      <li className="menu-item dropdown">
        <span className="menu-link">
          {title}
        </span>
        <ul className="dropdown-menu">
          {dropdownOptions.map((option, index) => (
            <li key={index}>
              <Link to={option.path} className="dropdown-link">
                {option.label}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return (
    <li className="menu-item">
      <Link 
        to={`/${title.toLowerCase().replace(" ", "-")}`} 
        className="menu-link"
        onClick={handleClick}
      >
        {title}
      </Link>
    </li>
  );
};

export default MenuItem;
