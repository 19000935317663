import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/global.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import FullscreenLayout from "./layouts/FullscreenLayout";
import GenerateAiPage from "./pages/GenerateAiPage";
import HotRecommendationPage from "./pages/HotRecommendationPage";
import Artworks from './pages/Artworks';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/inspiration/hot-recommendations" element={<HotRecommendationPage />} />
          <Route path="/artworks" element={<Artworks />} />
        </Route>

        <Route element={<FullscreenLayout />}>
          <Route path="/generate-ai" element={<GenerateAiPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;