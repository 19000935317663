import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Spinner, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import Toast from '../../components/common/Toast';
import { fetchArtworksAsync, deleteArtworkAsync } from '../../store/slices/artGenSlice';
import './styles.scss';

const Artworks = () => {
  const dispatch = useDispatch();
  const { artworks, loading, error } = useSelector((state) => state.artGen || {});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');

  useEffect(() => {
    dispatch(fetchArtworksAsync());
  }, [dispatch]);

  const handleCopyPrompt = async (prompt) => {
    try {
      await navigator.clipboard.writeText(prompt);
      setToastMessage('Prompt copied to clipboard!');
      setToastVariant('success');
      setShowToast(true);
    } catch (err) {
      setToastMessage('Failed to copy prompt');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `artwork-${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setToastMessage('Failed to download image');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  const handleDelete = async (task_id, image_url) => {
    if (window.confirm('Are you sure you want to delete this artwork?')) {
      try {
        await dispatch(deleteArtworkAsync(task_id, image_url));
        setToastMessage('Artwork deleted successfully');
        setToastVariant('success');
        setShowToast(true);
      } catch (err) {
        setToastMessage('Failed to delete artwork');
        setToastVariant('danger');
        setShowToast(true);
      }
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Container className="text-center py-4">
        <h6 className="text-danger">Error loading artworks</h6>
        <p className="text-muted">{error}</p>
      </Container>
    );
  }

  return (
    <Container className="artworks-container">
      <h1 className="mb-4 text-center mt-5">My Artworks</h1>
      
      {(!artworks || artworks.length === 0) ? (
        <div className="text-center py-4">
          <h6>No artwork found</h6>
          <p className="text-muted">
            Create your first artwork to see it here!
          </p>
        </div>
      ) : (
        <Row className="g-4">
          {artworks?.map((artwork, index) => (
            <Col xs={12} sm={6} md={4} key={artwork.task_id || index}>
              <div className="artwork-card position-relative">
                <img
                  src={artwork.image_urls[0] || ""}
                  alt={artwork.title || `Artwork ${index + 1}`}
                  className="img-fluid"
                />
                <div className="artwork-overlay">
                  <div className="artwork-actions">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Copy Prompt</Tooltip>}
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="me-2"
                        onClick={() => handleCopyPrompt(artwork.prompt)}
                      >
                        <CopyIcon />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Download Image</Tooltip>}
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="me-2"
                        onClick={() => handleDownload(artwork.image_urls[0])}
                      >
                        <DownloadIcon />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Delete Artwork</Tooltip>}
                    >
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => handleDelete(artwork.task_id, artwork.image_urls[0])}
                      >
                        <DeleteIcon />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
                {artwork.title && (
                  <div className="p-3">
                    <h6 className="artwork-title">{artwork.title}</h6>
                    {artwork.createdAt && (
                      <small className="text-muted">
                        {new Date(artwork.createdAt).toLocaleDateString()}
                      </small>
                    )}
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      )}

      <Toast
        show={showToast}
        message={toastMessage}
        variant={toastVariant}
        onClose={() => setShowToast(false)}
      />
    </Container>
  );
};

export default Artworks; 