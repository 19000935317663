import React from 'react';
import { Header } from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import './styles.scss';
import {Outlet} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";
import config from '../../config';

const MainLayout = () => {
  return (
    <div className="main-container">
      <GoogleOAuthProvider
        clientId={config.SOCIAL_LOGIN.GOOGLE.CLIENT_ID}
        redirectUri={config.SOCIAL_LOGIN.GOOGLE.REDIRECT_URI}
      >
        <Header />
        <div className="content-wrapper">
          <Outlet />
        </div>
        <Footer />
      </GoogleOAuthProvider>
    </div>
  );
};

export default MainLayout;