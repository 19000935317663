import React, {useEffect, useState} from "react";
import "../../../assets/scss/model-popup.scss";
import {Modal, Button, Pagination} from "react-bootstrap";

const StylePopup = ({ styles, show, hideStyleEditing, selectedStyle, setSelectedStyle }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = styles.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(styles.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const getPaginationItems = () => {
    const totalPages = Math.ceil(styles.length / itemsPerPage);
    const pages = [];

    if (totalPages <= 5) {
      // Nếu tổng số trang <= 5, hiển thị tất cả các trang
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        // Nếu đang ở gần trang đầu
        for (let i = 1; i <= 3; i++) {
          pages.push(i);
        }
        pages.push('...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Nếu đang ở gần trang cuối
        pages.push(1, '...');
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Nếu đang ở giữa
        pages.push(
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          '...',
          totalPages
        );
      }
    }

    return pages;
  };

  return (
    <Modal
      show={show}
      onHide={hideStyleEditing}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Style Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {currentItems.map((style) => (
            <div className="col-md-3 col-lg-3 mb-3" key={style.id}>
              <div className="card h-100">
                <img
                  src={style?.images[0] ?? ""}
                  className="card-img-top"
                  alt={style.name}
                  style={{ height: "120px", objectFit: "cover" }}
                />
                <div className="card-body text-start">
                  <div className="card-btn card-text">
                    <p className="card-text small">{style.desc}</p>
                    <Button
                      variant="primary"
                      className="btn-common btn-select"
                      onClick={() => {
                        setSelectedStyle(style);
                        hideStyleEditing();
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination className="justify-content-end">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {getPaginationItems().map((item, index) => (
            item === "..." ? (
              <Pagination.Ellipsis key={index} disabled />
            ) : (
              <Pagination.Item
                key={item}
                active={item === currentPage}
                onClick={() => handlePageChange(item)}
              >
                {item}
              </Pagination.Item>
            )
          ))}
          <Pagination.Next
            disabled={currentPage === Math.ceil(styles.length / itemsPerPage)}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideStyleEditing}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StylePopup;
