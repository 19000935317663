import { createSlice } from '@reduxjs/toolkit';
import { API_ENDPOINTS, axiosInstance, transformGenerateResponse } from '../../config/api.config';

const TASK_ID_KEY = 'task_id';

const initialState = {
  error: null,
  loading: false,
  artworks: [],
  taskId: localStorage.getItem(TASK_ID_KEY) || null,
  enhanceParams: {
    advance_enable: false,
    negative_prompt: '',
    width: 512,
    height: 512,
    face_detection: false,
    face_similarity_enable: false,
    face_similarity: 10,
    temperature: 10,
    steps: 50,
    number_of_images: 1
  }
};

const artGenSlice = createSlice({
  name: 'artGen',
  initialState,
  reducers: {
    generateSuccess: (state, action) => {
      state.error = null;
      state.taskId = action.payload.data.id;
      localStorage.setItem(TASK_ID_KEY, action.payload.data.id);
    },
    generateFailure: (state, action) => {
      state.error = action.payload;
      state.taskId = null;
      localStorage.removeItem(TASK_ID_KEY);
    },
    fetchResultSuccess: (state, action) => {
      state.error = null;
    },
    fetchResultFailure: (state, action) => {
      state.error = action.payload;
    },
    saveArtworkSuccess: (state) => {
      state.error = null;
      state.taskId = null;
      localStorage.removeItem(TASK_ID_KEY);
    },
    saveArtworkFailure: (state, action) => {
      state.error = action.payload;
    },
    fetchArtworksStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchArtworksSuccess: (state, action) => {
      state.loading = false;
      state.artworks = action.payload;
      state.error = null;
    },
    fetchArtworksFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteArtworkStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteArtworkSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.artworks = state.artworks.filter(
        artwork => artwork.task_id !== action.payload.task_id
      );
    },
    deleteArtworkFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  generateSuccess, 
  generateFailure,
  fetchResultSuccess,
  fetchResultFailure,
  saveArtworkSuccess,
  saveArtworkFailure,
  fetchArtworksStart,
  fetchArtworksSuccess,
  fetchArtworksFailure,
  deleteArtworkStart,
  deleteArtworkSuccess,
  deleteArtworkFailure
} = artGenSlice.actions;

// Async action creator for generation
export const generateArtAsync = (params) => async (dispatch) => {
  try {
    console.log('Starting generation...');

    const formData = new FormData();
    formData.append('prompt', params.prompt);
    // Only append model for V1 API
    if (process.env.REACT_APP_API_VERSION !== 'V2' && params.model?.code) {
      formData.append('model', params.model.code);
    }
    formData.append('type', 'text2image');
    
    const enhanceParams = params.enhanceParams || {};
    
    const paramKeys = [
      'negative_prompt',
      'width',
      'height',
      'face_detection',
      'face_similarity_enable',
      'face_similarity'
    ];

    // Only include temperature for V1 API
    if (process.env.REACT_APP_API_VERSION !== 'V2') {
      paramKeys.push('temperature');
    }

    // Map fields to their API parameter names
    const fieldMappings = {
      'face_detection': 'enable_image_face_detect',
      'temperature': 'cfg_scale',
      'face_similarity': 'face_generation_scale',
      'number_of_images': 'batch_size'
    };

    // Update paramKeys with mapped field names
    paramKeys.forEach(key => {
      if (enhanceParams[key]) {
        const mappedKey = fieldMappings[key] || key;
        // Convert face_generation_scale to proper format
        const formattedValue = mappedKey === 'face_generation_scale' 
          ? (enhanceParams[key] / 100).toFixed(2) 
          : enhanceParams[key];
        formData.append(mappedKey, formattedValue);
      }
    });

    if (enhanceParams.steps) {
      formData.append('steps', enhanceParams.steps);
    }

    if (enhanceParams.number_of_images) {
      formData.append('batch_size', enhanceParams.number_of_images);
    }

    if (params.style?.code) {
      formData.append('style', params.style.code);
    }

    if (params.ratio?.id) {
      formData.append('width', params.ratio?.width);
      formData.append('height', params.ratio?.height);
    }

    const response = await axiosInstance.post(API_ENDPOINTS.ART_GENERATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // Transform response based on API version
    const transformedResponse = transformGenerateResponse(response.data);
    
    dispatch(generateSuccess(transformedResponse));
    console.log('Generation successfully');
    
    return {
      type: 'artGen/generateSuccess',
      payload: transformedResponse
    };

  } catch (error) {
    console.error('Error generating art:', error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(generateFailure(errorMessage));
    return {
      type: 'artGen/generateFailure',
      payload: errorMessage
    };
  }
};

// Async action creator for fetching results
export const fetchResultAsync = (taskId) => async (dispatch) => {
  try {
    console.log('Fetching result for task:', taskId);

    const response = await axiosInstance.post(`${API_ENDPOINTS.ART_GENERATE}/fetch-result`, {
      task_id: taskId
    });

    console.log('Fetch result successfully');

    if (response.data.success) {
      dispatch(fetchResultSuccess(response.data.data));
      return {
        type: 'artGen/fetchResultSuccess',
        payload: response.data.data
      };
    } else {
      dispatch(fetchResultFailure(response.data.message || 'Failed to fetch result'));
      return {
        type: 'artGen/fetchResultFailure',
        payload: response.data.message || 'Failed to fetch result'
      };
    }
  } catch (error) {
    console.error('Fetch result error:', error.response || error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(fetchResultFailure(errorMessage));
    return {
      type: 'artGen/fetchResultFailure',
      payload: errorMessage
    };
  }
};

// Async action creator for surprise prompt
export const getSurprisePromptAsync = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/api/whee-prompt/surprise');
    return { type: 'artGen/surpriseSuccess', payload: response.data };
  } catch (error) {
    console.error('Error fetching surprise prompt:', error);
    return { type: 'artGen/surpriseFailure', payload: error.message };
  }
};

// Async action creator for saving artwork
export const saveArtworkAsync = (taskId, imageUrl) => async (dispatch) => {
  try {
    await axiosInstance.post('/api/art-gen/save', {
      task_id: taskId,
      image_url: imageUrl
    });
    
    dispatch(saveArtworkSuccess());
    return {
      type: 'artGen/saveArtworkSuccess'
    };
  } catch (error) {
    console.error('Error saving artwork:', error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(saveArtworkFailure(errorMessage));
    return {
      type: 'artGen/saveArtworkFailure',
      payload: errorMessage
    };
  }
};

// New action creator for fetching artworks
export const fetchArtworksAsync = () => async (dispatch) => {
  try {
    dispatch(fetchArtworksStart());
    const response = await axiosInstance.get('/api/art-gen/artworks');

    if (response.data.success) {
      dispatch(fetchArtworksSuccess(response.data.data?.items));
    } else {
      throw new Error(response.data.message || 'Failed to fetch artworks');
    }
  } catch (error) {
    console.error('Error fetching artworks:', error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(fetchArtworksFailure(errorMessage));
  }
};

// New thunk for deleting artwork
export const deleteArtworkAsync = (task_id, image_url) => async (dispatch) => {
  try {
    dispatch(deleteArtworkStart());
    await axiosInstance.post('/api/art-gen/delete-artwork', {
      task_id,
      image_url
    });
    
    dispatch(deleteArtworkSuccess({ task_id }));
  } catch (error) {
    console.error('Error deleting artwork:', error);
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(deleteArtworkFailure(errorMessage));
  }
};

export default artGenSlice.reducer; 