import infoIcon from "../../../assets/icons/info.svg";
import imageIcon from "../../../assets/icons/ic_image.svg";
import circleIcon from "../../../assets/icons/ic_circle.svg";
import checkboxIcon from "../../../assets/icons/checkbox.svg";
import {useState} from "react";
import RangeSlider from "../../common/RangeSlider";
import SwitchButton from "../../common/Form/SwitchButton";
import creditIcon from "../../../assets/icons/credit.svg";

const EnhanceOption = ({enhanceParams, setEnhanceParams}) => {
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleSliderChange = (name, newValue) => {
    setEnhanceParams((prevParams) => ({
      ...prevParams,
      [name]: newValue,
    }));
  };

  const handleNegativePromptChange = (e) => {
    setEnhanceParams({
      ...enhanceParams,
      negative_prompt: e.target.value
    });
  };

  return (
    <div className="option-div">
      <p className="title">Option</p>
      <div
        className="option-box option-box-enhance"
        onWheel={(e) => {
          // e.preventDefault();
          // e.currentTarget.scrollLeft += e.deltaY;
        }}
      >
        <div className="option-child">
          <label>Negative prompt <img src={infoIcon} alt={'info icon'} className="icon"/></label>
          <textarea 
            name="negative_prompt" 
            placeholder="Enter prompt"
            value={enhanceParams?.negative_prompt || ''}
            onChange={handleNegativePromptChange}
          />
        </div>

        <div className="option-child">
          <label>Upload original image <img src={infoIcon} alt={'info icon'} className="icon"/></label>
          <div className="d-flex upload-image">
            {image ? (
              <div className="position-relative me-2 image-uploaded">
                <img src={image} alt="Uploaded" className="" style={{width: "100px", height: "100px"}}/>
                <button
                  className="btn btn-danger btn-sm position-absolute top-0 end-0 icon-red"
                  onClick={handleRemoveImage}
                >
                  <img src={circleIcon} alt={"ic circle"} className="icon"/>
                </button>
              </div>
            ) : (
              <label className="d-flex justify-content-center align-items-center border-dash">
                <input
                  type="file"
                  accept="image/*"
                  className="d-none"
                  onChange={handleImageChange}
                />
                <span><img src={imageIcon} alt={"image icon"} className="icon img-icon-upload"/></span>
              </label>
            )}
          </div>
        </div>


        <div className="option-child">
          <label>Number of images <img src={infoIcon} alt={'info icon'} className="icon"/></label>
          <div className="d-flex text-start">
            {[1, 2, 3, 4].map((number_of_image, index) => (
              <div key={index}
                   className={`option-item number-of-image ${enhanceParams?.number_of_images && enhanceParams?.number_of_images === number_of_image ? "option-item-active" : ""}`}
                   onClick={() => handleSliderChange('number_of_images', number_of_image)}
              >
                <div className="icon-box">
                  <img src={imageIcon} alt={"image icon"} className="icon"/>
                </div>
                <span>{number_of_image} Image</span>
              </div>
            ))}
          </div>
        </div>

        <div className="option-child">
          <label>Image Upload </label>
          <div className="d-flex text-start flex-column">
            <div className="d-flex face-option w-100">
              <label>Face detection <img src={infoIcon} alt={'info icon'} className="icon"/></label>
              <div className="switch-btn">
                <SwitchButton name="face_detection" value={enhanceParams?.face_detection}
                onChange={() => handleSliderChange('face_detection', !enhanceParams?.face_detection)}/>
              </div>
            </div>
            <div className="d-flex face-option w-100">
              <label>Face similarity <img src={infoIcon} alt={'info icon'} className="icon"/></label>
              <div className="switch-btn">
                <SwitchButton name="face_similarity_enable" value={enhanceParams?.face_similarity_enable} 
                onChange={() => handleSliderChange('face_similarity_enable', !enhanceParams?.face_similarity_enable)}/>
              </div>
            </div>
            <RangeSlider
              name="face_similarity"
              value={enhanceParams?.face_similarity ?? 10}
              onChange={handleSliderChange}
            />
          </div>
        </div>

        <div className="option-child">
          <div className="option-header">
            <div className="option-title">
              <label className="title">Advanced</label>
              <label className="subtitle">Customize your experience</label>
            </div>
            <div className="switch-btn">
              <SwitchButton 
                name="advanced_enable" 
                value={enhanceParams?.advanced_enable}
                onChange={() => handleSliderChange('advanced_enable', !enhanceParams?.advanced_enable)}
              />
            </div>
          </div>
          {enhanceParams?.advanced_enable && (
            <div className="option-body">
              <div className="d-flex text-start flex-column">
                {process.env.REACT_APP_API_VERSION !== 'V2' && (
                  <div className="d-flex face-option face-option-advance w-100">
                    <label className="face-option-advance-label">
                      Temperature <img src={infoIcon} alt="info icon" className="icon" />
                    </label>
                    <RangeSlider
                      name="temperature"
                      value={enhanceParams?.temperature ?? 10}
                      onChange={handleSliderChange}
                    />
                  </div>
                )}
                <div className="d-flex face-option face-option-advance w-100">
                  <label className="face-option-advance-label">
                    Steps <img src={infoIcon} alt="info icon" className="icon" />
                  </label>
                  <RangeSlider
                    name="steps"
                    value={enhanceParams?.steps ?? 50}
                    onChange={handleSliderChange}
                  />
                </div>
                <div className="d-flex face-option face-option-advance w-100">
                  <div className="seed-section">
                    <div className="seed-header d-flex align-items-center justify-content-between">
                      <label className="face-option-advance-label">
                        Seed <img src={infoIcon} alt="info icon" className="icon" />
                      </label>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          name="random_seed"
                          id="random_seed"
                          checked={enhanceParams?.random_seed}
                          onChange={(e) => {
                            setEnhanceParams(prev => ({
                              ...prev,
                              random_seed: e.target.checked,
                              seed: e.target.checked ? undefined : prev.seed
                            }));
                          }}
                        />
                        <img src={checkboxIcon} alt="checkbox" className="checkbox-icon" />
                        <label htmlFor="random_seed">Random</label>
                      </div>
                    </div>
                    <input
                      type="number"
                      className="form-control seed-input mt-2"
                      name="seed"
                      value={enhanceParams?.random_seed ? '' : (enhanceParams?.seed ?? '')}
                      onChange={(e) => handleSliderChange('seed', parseInt(e.target.value))}
                      disabled={enhanceParams?.random_seed}
                      placeholder="Enter seed number"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="option-child">
          <div className="option-header">
            <div className="option-title">
              <label className="title">High resolution <img src={creditIcon} alt="credit icon" className="icon" /></label>
              <label className="subtitle">Get high quality results</label>
            </div>
            <div className="switch-btn">
              <SwitchButton 
                name="high_resolution" 
                value={enhanceParams?.high_resolution}
                disabled={true}
                onChange={() => handleSliderChange('high_resolution', !enhanceParams?.high_resolution)}
              />
            </div>
          </div>
        </div>

        <div className="option-child">
          <div className="option-header">
            <div className="option-title">
              <label className="title">Remove watermark <img src={creditIcon} alt="credit icon" className="icon" /></label>
              <label className="subtitle">Get results without watermark</label>
            </div>
            <div className="switch-btn">
              <SwitchButton 
                name="remove_watermark" 
                value={enhanceParams?.remove_watermark}
                disabled={true}
                onChange={() => handleSliderChange('remove_watermark', !enhanceParams?.remove_watermark)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnhanceOption;