import React from "react";
import "../../../assets/scss/model-popup.scss";
import { Modal, Button } from "react-bootstrap";

const ModelPopup = ({ models, show, hideModelEditing, selectedModel, setSelectedModel }) => {
  return (
    <Modal
      show={show}
      onHide={hideModelEditing}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Style Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {models.map((model) => (
            <div className="col-md-3 col-lg-3 mb-3" key={model.id}>
              <div className="card h-100">
                <img
                  src={model?.images[0] ?? ""}
                  className="card-img-top"
                  alt={model.name}
                  style={{ height: "120px", objectFit: "cover" }}
                />
                <div className="card-body text-start">
                  <div className="card-title">
                    <h6>{model.name}</h6>
                  </div>
                  <div className="card-btn card-text">
                    <p className="card-text small">{model.desc}</p>
                    <Button
                      variant="primary"
                      className="btn-common btn-select"
                      onClick={() => {
                        setSelectedModel(model);
                        hideModelEditing();
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModelEditing}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModelPopup;
