import { combineReducers } from "redux";
import ratiosReducer from "./ratios";
import modelsReducer from "./models";
import stylesReducer from "./styles";
import artHistoryReducer from "../slices/artHistorySlice";
import artGenReducer from "../slices/artGenSlice";

const rootReducer = combineReducers({
  ratios: ratiosReducer,
  models: modelsReducer,
  styles: stylesReducer,
  artHistory: artHistoryReducer,
  artGen: artGenReducer
});

export default rootReducer;