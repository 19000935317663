import React, { useEffect, useState } from 'react';
import { Button, Spinner, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArtHistory } from '../../../store/actions/history';
import { initialState } from '../../../store/slices/artHistorySlice';
import copyIcon from '../../../assets/icons/copy.svg';
import './history-option.scss';

const HistoryOption = ({ onSelectPrompt, isGenerating }) => {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  
  const artHistoryState = useSelector((state) => 
    state.artHistory || initialState
  );

  const { 
    data = { items: [], objects: { total: 0, count: 0 } }, 
    loading = false, 
    error = null, 
    currentPage = 1 
  } = artHistoryState;

  const { items: history, objects: { total, count } } = data;
  const totalPages = Math.ceil(total / 5); // 5 items per page

  useEffect(() => {
    dispatch(fetchArtHistory({ page: 1, itemsPerPage: 5 }));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (currentPage < totalPages && !isGenerating) {
      dispatch(fetchArtHistory({ 
        page: currentPage + 1, 
        itemsPerPage: 5
      }));
    }
  };

  const handleSelectPrompt = (item) => {
    if (!isGenerating) {
      onSelectPrompt({
        prompt: item.params.prompt,
        model: item.model,
        style: item.style,
        ratio: {
          width: parseInt(item.params.width),
          height: parseInt(item.params.height)
        },
        enhance_params: {
          steps: parseInt(item.params.steps),
          cfg_scale: item.params.cfg_scale,
          face_generation_scale: item.params.face_generation_scale,
          enable_image_face_detect: item.params.enable_image_face_detect,
          negative_prompt: item.params.negative_prompt
        }
      });
    }
  };

  const handleCopyPrompt = (e, prompt) => {
    e.stopPropagation(); // Prevent triggering the item click
    navigator.clipboard.writeText(prompt).then(() => {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000); // Hide after 2 seconds
    });
  };

  const isEmpty = !loading && Array.isArray(history) && history.length === 0;

  return (
    <div className={`history-option ${isEmpty ? 'empty' : ''} ${isGenerating ? 'disabled' : ''}`}>
      <Toast 
        show={showToast} 
        onClose={() => setShowToast(false)}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          background: '#333',
          color: 'white',
          zIndex: 9999
        }}
      >
        <Toast.Body>Prompt copied to clipboard!</Toast.Body>
      </Toast>
      
      <div className="history-title">History</div>
      
      {error && <div className="alert alert-danger">{error}</div>}
      
      {Array.isArray(history) && history.length > 0 ? (
        <div className="history-grid">
          {history.map((item, index) => (
            <div 
              key={item.id} 
              className="history-item" 
              onClick={() => handleSelectPrompt(item)}
              style={{ cursor: isGenerating ? 'not-allowed' : 'pointer' }}
            >
              <div className="history-prompt" title={item.params.prompt}>
                {item.params.prompt}
              </div>
              <button 
                className="copy-button" 
                onClick={(e) => handleCopyPrompt(e, item.params.prompt)}
                disabled={isGenerating}
              >
                <img src={copyIcon} alt="copy" />
              </button>
            </div>
          ))}
        </div>
      ) : !loading && (
        <div className="empty-message">
          No history found. Generate some images to see them here!
        </div>
      )}

      {loading && (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="light" />
        </div>
      )}

      {!loading && currentPage < totalPages && (
        <div className="text-center mt-4">
          <Button 
            variant="outline-light" 
            onClick={handleLoadMore}
            disabled={isGenerating}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default HistoryOption; 