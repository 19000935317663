import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.scss";

const RangeSlider = ({ name, value, onChange }) => {
  const handleSliderChange = (newValue) => {
    if (onChange) {
      onChange(name, newValue);
    }
  };

  const handleInputChange = (e) => {
    let newValue = Number(e.target.value);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      onChange(name, newValue);
    }
  };

  return (
    <div className="range-slider mt-1">
      <Slider
        min={0}
        max={100}
        step={1}
        marks={{ 0: "0", 25: "25", 50: "50", 75: "75", 100: "100" }}
        value={value}
        onChange={handleSliderChange}
      />
      <div className="range-input d-flex align-items-center">
        <input
          type="text"
          className="form-control"
          style={{ width: "100px" }}
          value={value}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
