import axios from "axios";

// Action Types
export const FETCH_STYLES_REQUEST = "FETCH_STYLES_REQUEST";
export const FETCH_STYLES_SUCCESS = "FETCH_STYLES_SUCCESS";
export const FETCH_STYLES_FAILURE = "FETCH_STYLES_FAILURE";

// Action Creators
export const fetchStyles = (page = 1, itemsPerPage = 100) => async (dispatch) => {
  dispatch({ type: FETCH_STYLES_REQUEST });
  try {
    const token = localStorage.getItem("token");
    
    // Check API version
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      // V2: Fetch style presets
      const response = await axios.get("https://cre8iv.appixi.net/api/style-presets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page || 1,
          items_per_page: itemsPerPage || 100,
        },
      });
      
      // Transform V2 style presets to match existing style format
      const transformedStyles = response.data.data.items.map(style => ({
        id: style.id,
        desc: style.name,
        name: style.name,
        images: [style.image_url], // Use the preset's image URL
        whee_external_id: `style_preset_${style.id}`, // Create a unique identifier
        parameters: style.parameters, // Keep the original parameters
        style_preset: style.parameters.style_preset // Add style_preset for easy access
      }));

      dispatch({ type: FETCH_STYLES_SUCCESS, payload: { data: transformedStyles } });
    } else {
      // V1: Keep existing LoRA list fetch
      const response = await axios.get("https://cre8iv.appixi.net/api/art-gen/get-lora-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: FETCH_STYLES_SUCCESS, payload: response.data });
    }
  } catch (error) {
    dispatch({ type: FETCH_STYLES_FAILURE, payload: error.message });
  }
};
