const isDevelopment = process.env.NODE_ENV === 'development';

const API_BASE_URL = isDevelopment 
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL_PROD;

const config = {
  // API Base URLs
  API_BASE_URL,

  // Base URL for non-authenticated endpoints (remove /api suffix)
  BASE_URL: API_BASE_URL.replace('/api', ''),

  // API Endpoints
  ENDPOINTS: {
    AUTH: {
      SOCIAL_LOGIN: '/auth/social/login'
    }
  },

  // Social Login Configuration
  SOCIAL_LOGIN: {
    FACEBOOK: {
      APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
      APP_SECRET: process.env.REACT_APP_FACEBOOK_APP_SECRET || '',
    },
    GOOGLE: {
      CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
      CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET || '',
      REDIRECT_URI: isDevelopment
        ? process.env.REACT_APP_GOOGLE_REDIRECT_URI_DEV
        : process.env.REACT_APP_GOOGLE_REDIRECT_URI_PROD
    }
  }
};

// Validate required environment variables
const requiredEnvVars = [
  'REACT_APP_API_URL_DEV',
  'REACT_APP_API_URL_PROD',
  'REACT_APP_GOOGLE_CLIENT_ID',
  'REACT_APP_GOOGLE_REDIRECT_URI_DEV',
  'REACT_APP_GOOGLE_REDIRECT_URI_PROD',
];

const missingEnvVars = requiredEnvVars.filter(varName => !process.env[varName]);

if (missingEnvVars.length > 0) {
  console.error('Missing required environment variables:', missingEnvVars);
  throw new Error(`Missing required environment variables: ${missingEnvVars.join(', ')}`);
}

export default config; 