import React from "react";
import { Button, Modal } from "react-bootstrap";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./login.scss";
import LoginPoster from "../../../assets/images/login_poster.png";
import GoogleIcon from "../../../assets/images/google-icon.png";
import FacebookIcon from "../../../assets/images/facebook-icon.png";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import config from "../../../config";

const LoginPopup = ({ show, handleClose, onSuccess }) => {
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      await handleSocialLogin(response.access_token, "google");
    },
    flow: "implicit",
    onError: (error) => {
      console.error("Google Login Error:", error);
    }
  });

  const handleSocialLogin = async (accessToken, provider) => {
    try {
      const formParams = new URLSearchParams();
      formParams.append("token", accessToken);
      formParams.append("provider", provider);

      const response = await axios.post(
        `${config.API_BASE_URL}${config.ENDPOINTS.AUTH.SOCIAL_LOGIN}`, 
        formParams,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      localStorage.setItem("user", JSON.stringify(response.data.profile));
      localStorage.setItem("token", response.data.access_token);
      handleClose();
      onSuccess?.();
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Login Error:", error.response?.data || error.message);
    }
  };

  const handleFacebookResponse = async (response) => {
    if (response.status === 'unknown' || !response.accessToken) {
      console.error('Facebook login failed or was cancelled');
      return;
    }
    
    await handleSocialLogin(response.accessToken, "facebook");
  };

  return (
      <Modal show={show} onHide={handleClose} size="xl" centered className="login-popup">
        <div className="row">
          <div className="col-6">
            <img src={LoginPoster} alt="login_poster" />
          </div>
          <div className="col-6 d-flex align-items-center justify-content-center">
            <div className="btn-close-white position-absolute" onClick={handleClose}>
              <span>X</span>
            </div>
            <Modal.Body className="text-center p-4">
              <h3 className="mb-5 title">Welcome to ImagineArt</h3>

              <Button className="btn-dark w-100 mb-3" onClick={() => {
                try {
                  login();
                } catch (error) {
                  console.error("Google login error:", error);
                }
              }}>
                <img src={GoogleIcon} alt="google icon" className="social-icon" />
                <span className="d-block w-100 text-center">Continue with Google</span>
              </Button>

              {/* Đăng nhập với Facebook */}
              <FacebookLogin
                appId={config.SOCIAL_LOGIN.FACEBOOK.APP_ID}
                autoLoad={false}
                callback={handleFacebookResponse}
                fields="name,picture"
                scope="public_profile"
                render={(renderProps) => (
                  <Button 
                    className="btn-dark w-100 mb-3" 
                    onClick={renderProps.onClick}
                    disabled={renderProps.isProcessing}
                  >
                    <img src={FacebookIcon} alt="facebook icon" className="social-icon" />
                    <span className="d-block w-100 text-center">
                      {renderProps.isProcessing ? 'Loading...' : 'Continue with Facebook'}
                    </span>
                  </Button>
                )}
              />

              <div className="hr-or d-flex align-items-center my-3">
                <hr className="flex-grow-1 me-2" />
                <span className="mx-2">or</span>
                <hr className="flex-grow-1 ms-2" />
              </div>
              <label className="text-start d-flex my-2">Email Address</label>
              <input type="email" className="form-control mb-3 email-input" placeholder="Enter your email..." />
              <Button className="w-100 btn-success">Continue</Button>
              <p className="term-text mt-5">
                By proceeding, you agree to our <a href="#" className="text-decoration-underline">Terms of use</a> and{" "}
                <a href="#" className="text-decoration-underline">Privacy Policy</a>.
              </p>
            </Modal.Body>
          </div>
        </div>
      </Modal>
  );
};

export default LoginPopup;
