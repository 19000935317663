import React from "react";
import "./style.scss";
import MenuItem from "./MenuItem";

export const Menu = () => {
  const dropdownOptions = [
    { label: "Selected Style", path: "/inspiration/selected-style" },
    { label: "Hot recommends", path: "/inspiration/hot-recommendations" },
  ];

  return (
    <nav className="menu-nav">
      <ul className="menu-list">
        <MenuItem title="Inspiration" />
        <MenuItem title="Download App" />
      </ul>
    </nav>
  );
};
