import cancelIcon from "../../../assets/icons/cancel.svg";
import { useEffect, useRef } from "react";
import PropTypes from 'prop-types';

const StyleOption = ({
  styles,
  activeStyle,
  setActiveStyle,
  apiVersion = 'v2', // Default to v2
  getStyleId = (style) => style?.whee_external_id, // Default to v2 style ID getter
  getStyleImage = (style) => style?.images?.[0], // Default image getter
  getStyleDescription = (style) => style?.desc, // Default description getter
}) => {
  const optionBoxRef = useRef(null);

  const handleStyleSelect = (style) => {
    setActiveStyle(style);
  };

  // Scroll to active style when option box opens
  useEffect(() => {
    if (optionBoxRef.current && activeStyle) {
      const activeElement = optionBoxRef.current.querySelector('.option-item-active');
      if (activeElement) {
        setTimeout(() => {
          const scrollOffset = activeElement.offsetTop - (optionBoxRef.current.offsetHeight / 2) + (activeElement.offsetHeight / 2);
          optionBoxRef.current.scrollTo({
            top: scrollOffset,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }, [activeStyle]);

  const isActiveStyle = (style) => {
    if (!activeStyle) return false;
    if (!style) return false;
    return getStyleId(activeStyle) === getStyleId(style);
  };

  return (
    <div className="option-div">
      <p className="title">Style</p>
      <div
        ref={optionBoxRef}
        className="option-box"
      >
        <div
          className={`option-item ${activeStyle === null ? "option-item-active" : ""}`}
          onClick={() => handleStyleSelect(null)}
        >
          <div className="icon-box">
            <img src={cancelIcon} alt="cancel-icon" className="icon"/>
          </div>
          <span>No Style</span>
        </div>
        {styles?.map((style) => (
          <div 
            key={getStyleId(style)}
            className={`option-item ${isActiveStyle(style) ? "option-item-active" : ""}`}
            onClick={() => handleStyleSelect(style)}
          >
            <div className="icon-box">
              <img src={getStyleImage(style) ?? cancelIcon} alt="style-icon" className="icon"/>
            </div>
            <span>{getStyleDescription(style)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

StyleOption.propTypes = {
  styles: PropTypes.array.isRequired,
  activeStyle: PropTypes.object,
  setActiveStyle: PropTypes.func.isRequired,
  apiVersion: PropTypes.oneOf(['v1', 'v2']),
  getStyleId: PropTypes.func,
  getStyleImage: PropTypes.func,
  getStyleDescription: PropTypes.func,
};

export default StyleOption;