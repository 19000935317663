import axios from 'axios';

// Action Types
export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILURE = 'FETCH_HISTORY_FAILURE';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';

// Action Creators
export const fetchHistoryStart = () => ({
  type: FETCH_HISTORY_START
});

export const fetchHistorySuccess = (data) => ({
  type: FETCH_HISTORY_SUCCESS,
  payload: data
});

export const fetchHistoryFailure = (error) => ({
  type: FETCH_HISTORY_FAILURE,
  payload: error
});

export const clearHistory = () => ({
  type: CLEAR_HISTORY
});

export const fetchArtHistory = ({ page = 1, itemsPerPage = 20 }) => async (dispatch) => {
  try {
    dispatch(fetchHistoryStart());
    
    const token = localStorage.getItem('token');
    if (!token) {
      return dispatch(fetchHistoryFailure('Authentication required. Please log in.'));
    }
    
    const response = await axios.get(
      `https://cre8iv.appixi.net/api/art-gen/history?page=${page}&items_per_page=${itemsPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    const result = response?.data || {};
    
    // Check if response has the expected structure
    if (!result.success) {
      return dispatch(fetchHistoryFailure('Invalid response from server'));
    }

    // The actual data structure from the API
    const historyData = {
      data: {
        items: result.data.items || [],
        objects: result.data.objects || {
          total: 0,
          count: 0
        }
      },
      currentPage: page
    };

    dispatch(fetchHistorySuccess(historyData));
  } catch (error) {
    console.error('Error fetching history:', error);
    dispatch(fetchHistoryFailure(error.message || 'Failed to fetch history'));
  }
}; 