import HotRecommend from "../components/features/List/HotRecommend";

const HotRecommendationPage = () => {
  return (
    <div className="bg-intro-2">
      <HotRecommend/>
    </div>
  );
}

export default HotRecommendationPage;