import cancelIcon from "../../../assets/icons/cancel.svg";

const ModelOption = ({models, activeModel, setActiveModel}) => {
  return (
    <div className="option-div">
      <p className="title">Model</p>
      <div
        className="option-box"
        onWheel={(e) => {
          e.preventDefault();
          e.currentTarget.scrollLeft += e.deltaY;
        }}
      >
        <div
          className={`option-item model-item ${activeModel?.id === null || activeModel === null ? "option-item-active" : ""}`} 
          onClick={() => setActiveModel(null)}
        >
          <div className="icon-box">
            <img src={cancelIcon} alt="cancel-icon" className="icon"/>
          </div>
          <span>No Model</span>
        </div>
        {process.env.REACT_APP_API_VERSION === 'V1' && models?.map((model) => (
          <div key={model?.id || model?.code}
               className={`option-item model-item ${activeModel?.id === model?.id ? "option-item-active" : ""}`}
               onClick={() => setActiveModel(model)}
          >
            <div className="icon-box">
              <img src={model?.images?.[0] ?? cancelIcon} alt="model-icon" className="icon"/>
            </div>
            <span>{model?.name}</span>
          </div>
        ))}

        {process.env.REACT_APP_API_VERSION === 'V2' && models?.map((model) => (
          <div key={model?.id || model?.model}
               className={`option-item model-item ${activeModel?.id === model?.id ? "option-item-active" : ""}`}
               onClick={() => setActiveModel(model)}
          >
            <div className="icon-box">
              <img src={model?.images?.[0] ?? cancelIcon} alt="model-icon" className="icon"/>
            </div>
            <span>{model?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModelOption;