import React from 'react';
import "../../../assets/scss/footer.scss";
import { Button } from "react-bootstrap";
import facebookIcon from "../../../assets/icons/ic_facebook.svg"
import instaIcon from "../../../assets/icons/ic_instagram.svg"
import youtubeIcon from "../../../assets/icons/ic_youtube.svg"
import logo from "../../../assets/icons/logo.svg";

const Footer = () => {
  return (
    <footer className="footer text-center pt-5">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>

      <div className="footer-menu d-flex mt-4">
        <ul className="d-flex">
          <li>Privacy & Cookie Notice</li>
          <li>App Privacy Notice</li>
          <li>Terms of Service</li>
          <li>Refund Policy</li>
          <li>Contact us</li>
        </ul>
      </div>

      <p className="footer-copyright">
        ©2025 Crea8ive. All rights reserved.
      </p>

      <div className="social-btn mt-4">
        <div className="footer-buttons d-flex justify-content-center gap-3">
          <img src={facebookIcon} alt="fb icon"/>
          <img src={instaIcon} alt="insta icon"/>
          <img src={youtubeIcon} alt="youtube icon"/>

        </div>
      </div>

    </footer>
  );
};

export default Footer;