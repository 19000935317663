import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles.scss';

const ImagePreviewModal = ({ show, onHide, imageUrl, taskId, onSave }) => {
  const handleDownload = async () => {
    try {
      // Fetch the image as blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      // Create a blob URL
      const blobUrl = window.URL.createObjectURL(blob);
      
      // Create and trigger download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `artwork-${taskId || Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleSave = () => {
    if (!taskId) {
      console.error('Cannot save artwork: No task ID available');
      return;
    }
    onSave(taskId, imageUrl);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="image-preview-modal"
    >
      <Modal.Header>
        <Modal.Title>Preview Image</Modal.Title>
        <div className="modal-header-buttons">
          <Button variant="primary" size="sm" onClick={handleDownload}>
            Download
          </Button>
          <Button 
            variant="success" 
            size="sm" 
            onClick={handleSave}
            title={'Save to My Artworks'}
          >
            Save to My Artworks
          </Button>
          <Button variant="secondary" size="sm" onClick={onHide}>
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="image-container">
          <img src={imageUrl} alt="Preview" className="preview-image" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreviewModal; 