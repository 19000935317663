import {
  FETCH_STYLES_REQUEST,
  FETCH_STYLES_SUCCESS,
  FETCH_STYLES_FAILURE,
} from "../actions/styles";

const initialState = {
  loading: false,
  styleList: [],
  error: null,
};

const stylesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STYLES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_STYLES_SUCCESS:
      return { ...state, loading: false, styleList: action.payload?.data ?? [] };
    case FETCH_STYLES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default stylesReducer;
