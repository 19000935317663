import { createSlice } from '@reduxjs/toolkit';
import {
  FETCH_HISTORY_START,
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_FAILURE,
  CLEAR_HISTORY
} from '../actions/history';

export const initialState = {
  data: {
    items: [],
    objects: {
      total: 0,
      count: 0
    }
  },
  loading: false,
  error: null,
  currentPage: 1
};

const artHistorySlice = createSlice({
  name: 'artHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FETCH_HISTORY_START, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FETCH_HISTORY_SUCCESS, (state, action) => {
        state.loading = false;
        state.error = null;
        
        // If it's the first page, replace the items
        if (action.payload.currentPage === 1) {
          state.data = action.payload.data;
        } else {
          // For subsequent pages, append items
          state.data.items = [...state.data.items, ...action.payload.data.items];
          state.data.objects = action.payload.data.objects;
        }
        
        state.currentPage = action.payload.currentPage;
      })
      .addCase(FETCH_HISTORY_FAILURE, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(CLEAR_HISTORY, (state) => {
        return initialState;
      });
  },
});

export default artHistorySlice.reducer; 