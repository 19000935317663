import ratioIcon3 from "../../../assets/icons/ratio_ic_3.svg";
import ratioIcon2 from "../../../assets/icons/ratio_ic_2.svg";
import ratioIcon1 from "../../../assets/icons/ratio_ic_1.svg";
import { useEffect, useRef } from "react";

const RatioOption = ({ratios, activeRatio, setActiveRatio, apiVersion = "V1", model = 'stable-diffusion-v1-6'}) => {
  const optionBoxRef = useRef(null);

  const handleRatioSelect = (ratio) => {
    setActiveRatio(ratio);
  };

  const v1Dimensions = {
    "16:9": "Widescreen",
    "1:1": "Square",
    "2:3": "Vertical",
    "3:4": "Portrait",
    "9:16": "Portrait",
    "3:2": "Classic",
    "4:3": "Standard"
  };

  // Transform ratios data for V2
  const filteredRatios = apiVersion === "V2" && model && ratios[model] ?
    Object.values(ratios[model]) :
    ratios;

  const ratioNames = apiVersion === "V2" ? 
    {} : // In V2, we'll use the ratio names from the API
    v1Dimensions;

  const getDimensions = (ratio) => {
    if (apiVersion === "V2" && ratio) {
      return `${ratio.width}x${ratio.height}`;
    }
    return "";
  };

  const mapRatioToName = (ratio) => {
    if (apiVersion === "V2") {
      return ratio?.name || "";
    }
    return ratioNames[ratio] || "";
  };

  const isActiveRatio = (ratio) => {
    if (!activeRatio) return false;
    return activeRatio.id === ratio.id;
  };

  const getRatioIcon = (ratioString) => {
    if (!ratioString) return ratioIcon3;
    
    const [a, b] = ratioString.split(':').map(Number);
    if (a > b) return ratioIcon2;
    if (a < b) return ratioIcon3;
    return ratioIcon1;
  };

  // Scroll to active ratio when option box opens
  useEffect(() => {
    if (optionBoxRef.current && activeRatio) {
      const activeElement = optionBoxRef.current.querySelector('.option-item-active');
      if (activeElement) {
        setTimeout(() => {
          const scrollOffset = activeElement.offsetLeft - (optionBoxRef.current.offsetWidth / 2) + (activeElement.offsetWidth / 2);
          optionBoxRef.current.scrollTo({
            left: scrollOffset,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }, [activeRatio]);

  return (
    <div className="option-div">
      <p className="title">Ratio</p>
      <div
        ref={optionBoxRef}
        className="option-box"
        onWheel={(e) => {
          e.preventDefault();
          e.currentTarget.scrollLeft += e.deltaY;
        }}
      >
        <div
          className={`option-item ratio-item ${activeRatio === null ? "option-item-active" : ""}`}
          onClick={() => handleRatioSelect(null)}
        >
          <div className="icon-box">
            <img src={getRatioIcon(null)} alt="ratio-icon" className="icon"/>
          </div>
          <span>No Ratio</span>
        </div>
        {filteredRatios?.map((ratio) => {
          return (
            <div key={ratio.id}
                 className={`option-item ratio-item ${isActiveRatio(ratio) ? "option-item-active" : ""}`}
                 onClick={() => handleRatioSelect(ratio)}
            >
              <div className="icon-box">
                <img src={getRatioIcon(ratio.ratio)} alt="ratio-icon" className="icon"/>
              </div>
              <span>
                {ratio.ratio} 
                {apiVersion === "V2" ? 
                  ` (${ratio.width}x${ratio.height}) `:
                  ` (${ratio.ratio}) `
                } 
                {mapRatioToName(ratio.ratio)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RatioOption;