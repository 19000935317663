import React from 'react';
import './styles.scss';
import {Outlet} from "react-router-dom";

const FullscreenLayout = () => {
  return (
    <div className="main-container">
      <div className="content-wrapper">
        <Outlet />
      </div>
    </div>
  );
};


export default FullscreenLayout;