import React from "react";
import "./style.scss";

const SwitchButton = ({ name, value, onChange, disabled }) => {
  const handleToggle = () => {
    if (onChange) {
      onChange(name, !value);
    }
  };

  return (
    <div className="switch">
      <label>
        <input
          type="checkbox"
          name={name}
          checked={value}
          disabled={disabled}
          onChange={handleToggle}
        />
        <span className="slider" />
      </label>
    </div>
  );
};

export default SwitchButton;
