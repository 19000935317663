import {useState, useRef, useEffect} from "react";
import profileIcon from "../../../assets/icons/profile.svg";
import editIcon from "../../../assets/icons/edit-icon.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import "./profile.scss";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = ({isOpen, closeProfile}) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const handleNavigate = (path) => {
    navigate(path);
    closeProfile(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  const avatar = user?.avatar || profileIcon;
  const haveAvatar = !!user?.avatar;
  const email = user?.email || "N/A";
  const name = user?.name || "N/A";

  return isOpen && (
    <div className="profile-popup position-absolute">
        <div ref={dropdownRef} className="d-flex flex-column justify-content-center align-items-center text-center">
          <div className="gap-2 d-flex flex-column text-center align-items-center">
            <button className={"btn btn-common btn-avatar"}>
              <img src={avatar} alt="Avatar" className={(haveAvatar ? "avatar-icon" : "profile-icon")} />
              <img src={editIcon} alt="Edit Icon" className="edit-icon" />
            </button>

            <div className="mt-2">
              <span className="text-sm bg-gray-600 rounded-full">Free</span>
              <p className="text-sm">{name}</p>
            </div>
          </div>
          <ul className="pt-2 mt-2">
            <li><img src={plusIcon} alt="plus-icon"/> Buy More Credit</li>
            <li onClick={() => handleNavigate('/artworks')}><img src={plusIcon} alt="plus-icon"/> My Artworks</li>
            <li><img src={plusIcon} alt="plus-icon"/> Support</li>
          </ul>
          <button className="text-center btn-sign-out w-100" onClick={handleSignOut}>Sign Out</button>
        </div>
    </div>
  );
};

export default ProfileDropdown;
