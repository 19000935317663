import axios from "axios";
import config from "../../config";

export const FETCH_RESULT_REQUEST = "FETCH_RESULT_REQUEST";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_FAILURE = "FETCH_RESULT_FAILURE";
export const GENERATE_IMAGE_REQUEST = "GENERATE_IMAGE_REQUEST";
export const GENERATE_IMAGE_SUCCESS = "GENERATE_IMAGE_SUCCESS";
export const GENERATE_IMAGE_FAILURE = "GENERATE_IMAGE_FAILURE";
export const GET_PROMPT_LIST_REQUEST = "GET_PROMPT_LIST_REQUEST";
export const GET_PROMPT_LIST_SUCCESS = "GET_PROMPT_LIST_SUCCESS";
export const GET_PROMPT_LIST_FAILURE = "GET_PROMPT_LIST_FAILURE";

// API Endpoints
const ENDPOINTS = {
  FETCH_RESULT: '/art-gen/fetch-result',
  GENERATE: '/art-gen',
  GET_PROMPT: '/art-gen/get-prompt-list'
};

// Basic token for non-authenticated requests
const BASIC_TOKEN = 'ca865b95840086a1bd7964ec3';

// Helper function to get the appropriate token
const getToken = (endpoint) => {
  // Always use basic token for get-prompt-list
  if (endpoint === ENDPOINTS.GET_PROMPT) {
    return BASIC_TOKEN;
  }
  // For other endpoints, use user token
  return localStorage.getItem("token");
};

// Helper function to get the appropriate URL based on endpoint
const getApiUrl = (endpoint) => {
  if (endpoint === ENDPOINTS.GET_PROMPT) {
    // For get-prompt-list, use BASE_URL
    return `${config.BASE_URL}${endpoint}`;
  }
  // For other endpoints, use API_BASE_URL
  return `${config.API_BASE_URL}${endpoint}`;
};

// Action Creators
export const fetchResult = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_RESULT_REQUEST });
  try {
    const token = getToken(ENDPOINTS.FETCH_RESULT);
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    const response = await axios.post(
      getApiUrl(ENDPOINTS.FETCH_RESULT),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: FETCH_RESULT_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: FETCH_RESULT_FAILURE, payload: error.message });
  }
};

export const generateImage = (payload) => async (dispatch) => {
  dispatch({ type: GENERATE_IMAGE_REQUEST });
  try {
    const token = getToken(ENDPOINTS.GENERATE);
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    const response = await axios.post(
      getApiUrl(ENDPOINTS.GENERATE),
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: GENERATE_IMAGE_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: GENERATE_IMAGE_FAILURE, payload: error.message });
  }
};

export const getPromptList = (params) => async (dispatch) => {
  dispatch({ type: GET_PROMPT_LIST_REQUEST });
  try {
    const token = getToken(ENDPOINTS.GET_PROMPT);
    const response = await axios.get(
      getApiUrl(ENDPOINTS.GET_PROMPT),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: params,
      }
    );
    dispatch({ type: GET_PROMPT_LIST_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: GET_PROMPT_LIST_FAILURE, payload: error.message });
  }
};
