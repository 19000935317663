import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import "../assets/scss/homepage.scss";
import androidDlImg from "../assets/icons/dl_android_img.svg";
import iosDlImg from "../assets/icons/dl_ios_img.svg";
import bannerHeroIntro2 from "../assets/images/banner_intro_section_2.png"
import bgHeroIntro2 from "../assets/images/bg_hero_2.png";
import imagePhoneIntro from "../assets/images/image_phone_intro.png";
import HotRecommendations from "../components/features/List/HotRecommend";
import {Typewriter} from "react-simple-typewriter";
import LoginPopup from "../components/common/Login/Popup";
import GenerateBox from "../components/features/GenerateAi/GenerateBox";

const HomePage = () => {
  const [text, setText] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [showGeneratePopup, setShowGeneratePopup] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const handleGenerate = () => {
    if (!user || !token) {
      setShowLogin(true);
      setShowGeneratePopup(false);
    } else {
      setShowLogin(false);
      setShowGeneratePopup(true);
    }
  }

  useEffect(() => {
    if (!user || !token) {
      setShowGeneratePopup(false);
    } else {
      setShowGeneratePopup(true);
    }

    // Check for selected prompt in localStorage
    const storedPrompt = localStorage.getItem('selectedPrompt');
    if (storedPrompt) {
      setSelectedPrompt(JSON.parse(storedPrompt));
      localStorage.removeItem('selectedPrompt');
    }
  }, []);

  return (
    <div className="background text-center">
      {!showGeneratePopup && (
        <div className="intro-1">
          <Container fluid className="px-0 gx-0">
            <div className="text-hero position-relative">
              <span className="text-center text-hero-description">Cre8ive AI Art Generator</span>
              <h1>Cre8ive AI Art Generator</h1>
              <span>Create AI Art and turn your imaginations into reality with Imagines AI Art Generator and produce stunning visuals to cover up your artistic thoughts.</span>
              <div className="generate-intro-box">
                <div className="generate-wrapper">
                <span className="p-2 generate-input text-start">
                  <Typewriter
                    words={['Explore AI-generated images in diverse styles...', 'A magical Disney-inspired castle...']}
                    loop={0}
                    cursor
                    typeSpeed={30}
                    deleteSpeed={10}
                    delaySpeed={1000}
                    onType={setText}
                  />
                </span>

                  <input
                    type="text"
                    name="generate-input"
                    value={text}
                    placeholder="Explore AI-generated images in diverse styles..."
                    readOnly
                    className="border p-2 d-none"
                  />
                  <button className="btn btn-info btn-generate" onClick={handleGenerate}>Generate</button>

                </div>
              </div>
              <div className="dl-box d-flex">
                <img src={androidDlImg} alt="android dl img"/>
                <img src={iosDlImg} alt="ios dl img" className="ms-2"/>
              </div>
            </div>
          </Container>

          <img className="image-hero-section-2" src={bannerHeroIntro2} alt="image hero section 2"/>
        </div>
      )}

      <LoginPopup show={showLogin} handleClose={setShowLogin}/>
      {showGeneratePopup && (
        <GenerateBox 
          show={showGeneratePopup} 
          onClose={setShowGeneratePopup}
          initialPrompt={selectedPrompt}
          onPromptUsed={() => setSelectedPrompt(null)}
        />
      )}

      <div className="intro-2 container-fluid">
        <div className="text-hero-2">
          <img src={bgHeroIntro2} alt="bg hero intro 2"/>
          <h1>Discover AI Art</h1>
          <span className="mt-3 d-flex">Explore a stunning collection of AI-generated images in diverse styles, brought to life by CRE8TIVE. Unleash creativity through the power of AI-crafted visuals.</span>
        </div>
      </div>

      <HotRecommendations onPromptSelect={(prompt) => {
        setSelectedPrompt(prompt);
        setShowGeneratePopup(true);
      }}/>

      <div className="intro-1 container-fluid" id="download-app">
        <div className="text-hero mt-5">
          <h1>Download App</h1>
          <span className="mt-3 d-flex">An intelligent image creation application based on descriptions, allowing you to easily turn any idea into a unique piece of art within seconds.</span>

          <div className="dl-box d-flex">
            <img src={androidDlImg} alt="android dl img"/>
            <img src={iosDlImg} alt="ios dl img" className="ms-2"/>
          </div>

        </div>
      </div>

      <img className="image-phone-intro mt-5" src={imagePhoneIntro} alt="image phone intro"/>

    </div>
  );
};

export default HomePage;