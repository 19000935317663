import React from 'react';
import { Toast as BootstrapToast } from 'react-bootstrap';
import './styles.scss';

const Toast = ({ show, message, onClose, variant = 'success' }) => {
  return (
    <div className="toast-container">
      <BootstrapToast 
        show={show} 
        onClose={onClose}
        delay={3000}
        autohide
        className={`custom-toast ${variant}`}
      >
        <BootstrapToast.Body>
          {message}
        </BootstrapToast.Body>
      </BootstrapToast>
    </div>
  );
};

export default Toast; 