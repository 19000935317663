import {Button, Collapse} from "react-bootstrap";
import {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRatios} from "../../../store/actions/ratios";
import {fetchModels} from "../../../store/actions/models";
import {fetchStyles} from "../../../store/actions/styles";
import {fetchArtHistory} from "../../../store/actions/history";
import {
  generateArtAsync,
  fetchResultAsync,
  getSurprisePromptAsync,
  saveArtworkAsync
} from "../../../store/slices/artGenSlice";
import "./generate-popup.scss"
import historyIcon from "../../../assets/icons/history.svg";
import ideaIcon from "../../../assets/icons/idea.svg";
import arrowUp from "../../../assets/icons/arrow-up.svg";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import enhanceIcon from "../../../assets/icons/enhance.svg";
import proIcon from "../../../assets/icons/pro.svg";
import StyleOption from "./StyleOption";
import ModelOption from "./ModelOption";
import RatioOption from "./RatioOption";
import EnhanceOption from "./EnhanceOption";
import HistoryOption from "./HistoryOption";
import ImagePreviewModal from "../../common/ImagePreviewModal";
import { useNavigate } from "react-router-dom";
import Toast from "../../common/Toast";

const GenerateBox = ({ show, onClose, initialPrompt, onPromptUsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openBox, setOpenBox] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [activeModel, setActiveModel] = useState(null);
  const [activeStyle, setActiveStyle] = useState(null);
  const [activeRatio, setActiveRatio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSurpriseLoading, setIsSurpriseLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [enhanceParams, setEnhanceParams] = useState(initialPrompt?.enhance_params);
  const [errorMessage, setErrorMessage] = useState(null);
  const [numberOfImages, setNumberOfImages] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [taskIdResult, setTaskIdResult] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const promptTextareaRef = useRef(null);

  const {
    error,
    taskId,
    ratios,
    models,
    styles
  } = useSelector((state) => ({
    ...state.artGen,
    ratios: state.ratios.ratioList,
    models: state.models.modelList,
    styles: state.styles.styleList,
  }));

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    return token && user;
  };

  const fetchData = () => {
    if (!checkAuth()) {
      // setErrorMessage("Please login to continue");
      return;
    }
    dispatch(fetchRatios());
    dispatch(fetchModels());
    dispatch(fetchStyles());
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Update number of images when enhanceParams changes
    if (enhanceParams?.number_of_images) {
      setNumberOfImages(enhanceParams.number_of_images);
    }
  }, [enhanceParams]);

  const formatPromptText = (text) => {
    if (!text) return "";
    return text.trim().replace(/\s+/g, " ");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkAuth()) {
      setErrorMessage("Please login to generate images");
      return;
    }

    // Only check for model in V1 API
    if (process.env.REACT_APP_API_VERSION !== 'V2' && !activeModel) {
      setErrorMessage("Please select a model");
      return;
    }

    if (!prompt) {
      setErrorMessage("Please enter a prompt");
      return;
    }

    try {
      setIsLoading(true);
      setGeneratedImage(null);
      setErrorMessage(null);
      setOpenBox(null);
      
      // For v2 API, transform the style parameter
      const enhancedParams = {
        ...enhanceParams,
        style_preset: activeStyle?.parameters?.style_preset,
        number_of_images: numberOfImages || 1
      };
      
      const genResult = await dispatch(generateArtAsync({
        prompt,
        model: process.env.REACT_APP_API_VERSION === 'V2' ? null : activeModel,
        style: activeStyle,
        ratio: activeRatio,
        enhanceParams: enhancedParams
      }));

      if (genResult.type === 'artGen/generateSuccess') {
        if (process.env.REACT_APP_API_VERSION === 'V2') {
          // For V2 API, store the seed as taskId if needed for reference
          setTaskIdResult(genResult.payload.data.images[0].seed);
          setGeneratedImage(genResult.payload.data.images);
        } else {
          // Existing V1 API flow
          setTaskIdResult(genResult.payload.data.id);
          const fetchResult = await dispatch(fetchResultAsync(genResult.payload.data.id));
          
          if (fetchResult.type === 'artGen/fetchResultSuccess') {
            setGeneratedImage(fetchResult.payload);
          } else {
            if (fetchResult.payload.includes('Image generation still in progress')) {
              // Retry with exponential backoff
              let retryCount = 0;
              const maxRetries = 10;
              const baseDelay = 2000;

              const retryFetch = async () => {
                if (retryCount >= maxRetries) {
                  setErrorMessage('Generation timed out. Please try again.');
                  return;
                }

                await new Promise(resolve => setTimeout(resolve, baseDelay * Math.pow(2, retryCount)));
                const retryResult = await dispatch(fetchResultAsync(genResult.payload.data.id));
                
                if (retryResult.type === 'artGen/fetchResultSuccess') {
                  setGeneratedImage(retryResult.payload);
                } else if (retryResult.payload.includes('Image generation still in progress')) {
                  retryCount++;
                  await retryFetch();
                } else {
                  setErrorMessage(retryResult.payload);
                }
              };

              await retryFetch();
            } else {
              setErrorMessage(fetchResult.payload);
            }
          }
        }
        dispatch(fetchArtHistory({ page: 1, itemsPerPage: 5 }));
      } else {
        setErrorMessage(genResult.payload);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Failed to generate art. Please try again.');
      console.log(error);
    }
  };

  const handleRetry = async () => {
    // Skip retry functionality for V2 API
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      alert('Retry not available in current version');
      return;
    }

    if (!taskId) {
      alert('No pending generation found');
      return;
    }

    try {
      setIsLoading(true);
      const result = await dispatch(fetchResultAsync(taskId));
      if (result.type === 'artGen/fetchResultSuccess') {
        setGeneratedImage(result.payload);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching result:', error);
      alert('Failed to fetch result. Please try again later.');
    }
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  // Handle initial prompt
  useEffect(() => {
    if (initialPrompt) {
      setPrompt(initialPrompt.prompt);
      if (initialPrompt.model) setActiveModel(initialPrompt.model);
      if (initialPrompt.style) setActiveStyle(initialPrompt.style);
      if (initialPrompt.ratio) setActiveRatio(initialPrompt.ratio);
      if (initialPrompt.enhance_params) {
        setEnhanceParams(prevParams => ({
          ...prevParams,
          ...initialPrompt.enhance_params
        }));
      }
      // Scroll to top and focus textarea
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        if (promptTextareaRef.current) {
          promptTextareaRef.current.focus();
        }
      }, 100);
      
      onPromptUsed?.();
    }
  }, [initialPrompt]);

  const toggleBox = (box) => {
    setOpenBox(openBox === box ? null : box);
  };

  const handleImageClick = (imageUrl, isWatermarked) => {
    if (!isWatermarked) {
      setSelectedImage(imageUrl);
      setShowPreviewModal(true);
    }
  };

  const handleSaveArtwork = async (taskId, imageUrl) => {
    // For V2 API, we don't need taskId
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      try {
        const result = await dispatch(saveArtworkAsync(null, imageUrl));
        if (result.type === 'artGen/saveArtworkSuccess') {
          setShowPreviewModal(false);
          setToastMessage("Artwork saved successfully!");
          setToastVariant("success");
          setShowToast(true);
        }
      } catch (error) {
        console.error('Error saving artwork:', error);
        setToastMessage("Failed to save artwork");
        setToastVariant("error");
        setShowToast(true);
      }
      return;
    }

    // V1 API flow
    if (!taskId) {
      console.error('No taskId available for saving artwork');
      return;
    }
    
    try {
      const result = await dispatch(saveArtworkAsync(taskId, imageUrl));
      if (result.type === 'artGen/saveArtworkSuccess') {
        setShowPreviewModal(false);
        setToastMessage("Artwork saved successfully!");
        setToastVariant("success");
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error saving artwork:', error);
      setToastMessage("Failed to save artwork");
      setToastVariant("error");
      setShowToast(true);
    }
  };

  const handleHistoryPromptSelect = (historyItem) => {
    setPrompt(historyItem.prompt);
    setActiveModel(historyItem.model);
    setActiveStyle(historyItem.style);
    setActiveRatio(historyItem.ratio);
    setEnhanceParams(historyItem.enhance_params);
  };

  const findMatchingRatio = (params) => {
    if (!params) return null;
    
    // First try to match by all criteria
    let matchedRatio = ratios?.find(ratio => 
      ratio.ratio === params.pic_ratio && 
      ratio.width === params.width && 
      ratio.height === params.height
    );

    // If no match found, try matching just by ratio
    if (!matchedRatio) {
      matchedRatio = ratios?.find(ratio => ratio.ratio === params.pic_ratio);
    }

    return matchedRatio || null;
  };

  const findMatchingModel = (modelId) => {
    return models?.find(model => model.whee_external_id === modelId);
  };

  const handleSurpriseMe = async () => {
    if (!checkAuth()) {
      setErrorMessage("Please login to use surprise me");
      return;
    }

    try {
      setIsSurpriseLoading(true);
      setIsLoading(true);
      setErrorMessage(null);
      
      const result = await dispatch(getSurprisePromptAsync());
      
      if (result.type === 'artGen/surpriseSuccess') {
        const surpriseData = result.payload.data;
        
        // Find matching model, ratio from the surprise data
        const matchedModel = findMatchingModel(surpriseData.model_id);
        const matchedRatio = findMatchingRatio(surpriseData.params);
        
        // Update form state with surprise data
        setPrompt(formatPromptText(surpriseData.prompt_en));
        if (matchedModel) setActiveModel(matchedModel);
        if (matchedRatio) setActiveRatio(matchedRatio);
        
        // Update enhance params
        setEnhanceParams({
          face_detection: surpriseData.params?.facial_detection,
          negative_prompt: formatPromptText(surpriseData.params?.negative_prompt_en),
          face_similarity: surpriseData.params?.face_generation_scale || 30,
          advanced_enable: surpriseData.params?.steps > 0 || surpriseData.params?.cfg_scale > 0,
          face_similarity_enable: surpriseData.params?.steps > 0 || surpriseData.params?.cfg_scale > 0,
          steps: surpriseData.params?.steps,
          number_of_images: surpriseData.params?.batch_size || 1,
          temperature: surpriseData.params?.cfg_scale,
          seed: surpriseData.params?.seed || -1,
          random_seed: surpriseData.params?.seed === -1
        });
      } else {
        setErrorMessage("Failed to get surprise prompt");
      }
    } catch (error) {
      setErrorMessage("Failed to get surprise prompt");
      console.error('Error in surprise me:', error);
    } finally {
      setIsLoading(false);
      setIsSurpriseLoading(false);
    }
  };

  const apiVersion = process.env.REACT_APP_API_VERSION || "v1";
  // Add API version specific style handlers
  const getStyleId = (style) => {
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      return style?.whee_external_id;
    }
    return style?.id;
  };

  const getStyleImage = (style) => {
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      return style?.images?.[0];
    }
    return style?.image_url;
  };

  const getStyleDescription = (style) => {
    if (process.env.REACT_APP_API_VERSION === 'V2') {
      return style?.desc;
    }
    return style?.description;
  };

  if (error) {
    console.error('Error generating image:', error);
  }

  return (
    <div className="generate-popup">
      <Toast 
        show={showToast}
        message={toastMessage}
        variant={toastVariant}
        onClose={() => setShowToast(false)}
      />
      <div className="popup-header d-flex justify-content-between">
        <Button 
          variant="dark" 
          className="btn-header gap-2" 
          onClick={() => toggleBox("history")}
          disabled={isLoading}
        >
          <img src={historyIcon} alt="history icon" className="icon"/> History
        </Button>
        <Button 
          variant="dark" 
          className="btn-header gap-2" 
          onClick={handleSurpriseMe}
          disabled={isLoading}
        >
          <img src={ideaIcon} alt="idea icon" className="icon"/>Surprise me
        </Button>
        
      </div>
    
      <textarea 
        ref={promptTextareaRef}
        className="prompt-input form-control my-4" 
        name="prompt" 
        placeholder="Enter prompt..."
        value={prompt}
        onChange={handlePromptChange}
        disabled={isLoading}
      />
      <div className="popup-footer d-flex justify-content-between align-items-center">
        <div className="options d-flex gap-2">
          {["style", "model", "ratio"].map((box) => (
            <div key={box}>
              <Button 
                variant="dark" 
                onClick={() => toggleBox(box)} 
                className="option-box-select"
                disabled={isLoading}
              >
                <span>{box.charAt(0).toUpperCase() + box.slice(1)}</span>
                <img src={openBox === box ? arrowUp : arrowDown} alt="toggle"/>
              </Button>
            </div>
          ))}
        </div>

        <div className="generate-box align-items-end d-flex gap-2">
          <Button 
            className="btn btn-enhance btn-dark" 
            onClick={() => toggleBox("enhance")}
            disabled={isLoading}
          >
            <img src={enhanceIcon} alt="enhance icon" className="enhance-icon"/>
          </Button>
          <Button 
            variant="success" 
            className="btn-generate"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Generating...' : 'Generate'}
          </Button>
        </div>
      </div>
      <hr/>

      <Collapse in={openBox === "style"}>
        <div>
          <StyleOption
            styles={styles}
            activeStyle={activeStyle}
            setActiveStyle={setActiveStyle}
            apiVersion={process.env.REACT_APP_API_VERSION}
            getStyleId={getStyleId}
            getStyleImage={getStyleImage}
            getStyleDescription={getStyleDescription}
          />
        </div>
      </Collapse>

      <Collapse in={openBox === "model"}>
        <div>
          <ModelOption activeModel={activeModel} setActiveModel={setActiveModel} models={models} />
        </div>
      </Collapse>

      <Collapse in={openBox === "ratio"}>
        <div>
          <RatioOption apiVersion={apiVersion} ratios={ratios} activeRatio={activeRatio} setActiveRatio={setActiveRatio} model={activeModel?.model}  />
        </div>
      </Collapse>

      <Collapse in={openBox === "enhance"}>
        <div>
          <EnhanceOption enhanceParams={enhanceParams} setEnhanceParams={setEnhanceParams} />
        </div>
      </Collapse>

      <Collapse in={openBox === "history"}>
        <div>
          <HistoryOption onSelectPrompt={handleHistoryPromptSelect} isGenerating={isLoading} />
        </div>
      </Collapse>

      {errorMessage && (
        <span className="d-block text-danger mt-3 text-center">
          {errorMessage}
        </span>
      )}

      {/* Result Section */}
      {(!isSurpriseLoading && (isLoading || generatedImage)) && (
        <div className="result-section mt-4">
          <h4 className="mb-3">Results</h4>
          <div className="generated-images-grid">
            {isLoading ? (
              // Show loading placeholders
              Array(numberOfImages).fill(null).map((_, index) => (
                <div key={index} className="generated-image-container loading" />
              ))
            ) : (
              // Show generated images
              generatedImage.slice(0, numberOfImages).map((imageUrl, index) => (
                <div 
                  key={index} 
                  className={`generated-image-container ${index > 0 ? 'watermarked' : ''}`}
                  onClick={() => handleImageClick(imageUrl, index > 0)}
                  style={{ cursor: index > 0 ? 'default' : 'pointer' }}
                >
                  <img 
                    src={imageUrl} 
                    alt={`Generated artwork ${index + 1}`} 
                    className="img-fluid rounded"
                  />
                  {index > 0 && (
                    <>
                      <div className="pro-badge">
                        <img src={proIcon} alt="PRO" className="pro-icon" />
                        <span className="pro-text">PRO</span>
                      </div>
                      <div className="unlock-text">Unlock Everything</div>
                    </>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}

      <ImagePreviewModal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        imageUrl={selectedImage}
        taskId={process.env.REACT_APP_API_VERSION === 'V2' ? null : taskIdResult}
        onSave={handleSaveArtwork}
      />
    </div>
  );
};

export default GenerateBox;