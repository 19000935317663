import axios from 'axios';

export const API_BASE_URL = 'https://cre8iv.appixi.net';

// API Version configurations
export const API_VERSIONS = {
  V1: 'v1',
  V2: 'v2'
};

export const API_CONFIG = {
  [API_VERSIONS.V1]: {
    ART_GENERATE: '/api/art-gen',
    RATIOS: '/ratios',
    MODELS: '/models',
    STYLES: '/styles'
  },
  [API_VERSIONS.V2]: {
    ART_GENERATE: '/api/stability-ai/generate-image',
    RATIOS: '/ratios',
    MODELS: '/models',
    STYLES: '/styles'
  }
};

// Get API version from environment variable, default to V1 if not set
const currentApiVersion = process.env.REACT_APP_API_VERSION === 'V2' ? API_VERSIONS.V2 : API_VERSIONS.V1;

export const API_ENDPOINTS = new Proxy({}, {
  get: (target, prop) => {
    return API_CONFIG[currentApiVersion][prop];
  }
});

// Helper to transform response based on API version
export const transformGenerateResponse = (response) => {
  if (currentApiVersion === API_VERSIONS.V2) {
    // Transform V2 response to match V1 format
    return {
      success: response.success,
      data: {
        id: response.data.task_id,
        images: response.data.images.map(img => img)
      },
      message: response.message
    };
  }
  return response;
};

// Create axios instance with default config
export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

// Add request interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export const getAuthHeader = () => ({
  'Authorization': `Bearer ${localStorage.getItem('token')}`
}); 