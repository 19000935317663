import axios from "axios";

// Action Types
export const FETCH_RATIOS_REQUEST = "FETCH_RATIOS_REQUEST";
export const FETCH_RATIOS_SUCCESS = "FETCH_RATIOS_SUCCESS";
export const FETCH_RATIOS_FAILURE = "FETCH_RATIOS_FAILURE";

// Action Creators
export const fetchRatios = () => async (dispatch) => {
  dispatch({ type: FETCH_RATIOS_REQUEST });
  try {
    const token = localStorage.getItem("token");
    const apiVersion = process.env.REACT_APP_API_VERSION || "V1";
    if (apiVersion === "V2") {
      const response = await axios.get("https://cre8iv.appixi.net/api/stability-ai/get-ratios", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: FETCH_RATIOS_SUCCESS, payload: response.data });
    } else {
      const response = await axios.get("https://cre8iv.appixi.net/api/art-gen/get-ratios", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: FETCH_RATIOS_SUCCESS, payload: response.data });
    }
  } catch (error) {
    dispatch({ type: FETCH_RATIOS_FAILURE, payload: error.message });
  }
};
