import React, {useState} from "react";
import "../../../assets/scss/header.scss";
import logo from "../../../assets/icons/logo.svg";
import {Button} from "react-bootstrap";
import {Menu} from "../Menu/Menu";
import {useNavigate} from "react-router-dom";
import LoginPopup from "../Login/Popup";
import creditIcon from "../../../assets/icons/credit.svg";
import premiumIcon from "../../../assets/icons/premium.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import ProfileDropdown from "../../features/Profile/Dropdown";

const MenuItem = ({title, withDropdown}) => (
  <div className="content-wrapper">
    <div className="content">
      <div className="text-wrapper">{title}</div>
      {withDropdown && <i className="fas fa-chevron-down ic-arrow-down"/>}
    </div>
  </div>
);

export const Header = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);


  const handleClose = () => {
    setShowLogin(false);
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  return (
    <div className="menu-container">
      <div className="col-9 d-flex menu-box">
        <div className="logo">
          <img alt="logo" src={logo}/>
        </div>
        <div className="menu">
          <Menu/>
        </div>
      </div>

      {user && token ? (
        <div className="logged-in-buttons sign-in-box col-3">
          <button className="btn btn-common btn-credits"><img src={creditIcon} alt="credit icon"/>5 Token</button>
          <button className="btn btn-common btn-premium"><img src={premiumIcon} alt="premium icon"/> Pro</button>
          <button className="btn btn-common btn-profile" onClick={setOpenProfile}>
            <img src={profileIcon} alt={"profile icon"}/>
          </button>
          <ProfileDropdown isOpen={openProfile} closeProfile={setOpenProfile}/>
        </div>
      ) : (
        <div className="sign-in-box col-3">
          <Button className="btn btn-common sign-in-btn" onClick={() => setShowLogin(true)}>Login</Button>
        </div>
      )}

      <LoginPopup show={showLogin} handleClose={handleClose}/>
    </div>
  );
};
