import "../assets/scss/generate-ai.scss";
import icCredit from "../assets/images/ic_credits.png";
import avatar from "../assets/images/eclipse_49.png";
import logoIcon from "../assets/images/logo_icon.png";
import {useNavigate} from "react-router-dom";
import {Badge, Button, FormControl} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRatios} from "../store/actions/ratios";
import {fetchModels} from "../store/actions/models";
import ModelPopup from "../components/features/Model/Popup";
import StylePopup from "../components/features/Style/Popup";
import {fetchStyles} from "../store/actions/styles";
import Spinner from 'react-bootstrap/Spinner';
import ResultIcon from "../assets/images/result_img.png";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {fetchResult, generateImage} from "../store/actions/artgen";

const GenerateAiPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {ratios, models, styles} = useSelector((state) => ({
    ratios: state.ratios.ratioList,
    models: state.models.modelList,
    styles: state.styles.styleList,
  }));

  useEffect(() => {
    dispatch(fetchRatios());
    dispatch(fetchModels());
    dispatch(fetchStyles());
  }, []);

  useEffect(() => {
    if (models.length > 0) {
      setSelectedModel(models[0]);
    }
  }, [models]);

  const handleBackButton = () => {
    navigate("/home");
  };

  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [showModelEdit, setShowModelEdit] = useState(null);
  const [showStyle, setShowStyle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);


  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue("preview", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (setFieldValue) => {
    setFieldValue("preview", null);
  };

  const uiEvents = {
    hideModelEditing: () => {
      setShowModelEdit(false);
    },
    hideStyleEditing: () => {
      setShowStyle(false);
    },
    removeStyle: () => {
      setSelectedStyle(null);
    }

  }

  const removeStyle = () => {
    uiEvents.removeStyle();
  }
  const initialValues = {
    prompt: "",
    preview: null
  };

  const handleSubmit = async (values, {setSubmitting}) => {
    console.log("Form Submitted with values:", values);
    if (!selectedModel) {
      alert("Please select a model");
      console.log(values);
      return;
    }
    let params = {...values, model: selectedModel?.code};
    if (selectedStyle?.model) {
      params = {...params, style: selectedStyle.model}
    }
    setIsLoading(true);
    setSubmitting(true);
    setPrompt(values.prompt);


    try {
      const response = await dispatch(generateImage(params));
      const taskId = response.data?.id;

      if (!taskId) {
        console.log('Không tìm thấy task_id trong phản hồi generate image');
        setIsLoading(false);
        setSubmitting(false);
        throw new Error('Không tìm thấy task_id trong phản hồi generate image');
      } else {
        const resultResponse = await dispatch(fetchResult({task_id: taskId}));
        setResult(resultResponse?.data);
      }
    } catch (error) {
      console.error('Có lỗi xảy ra:', error.message);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    prompt: Yup.string().required("Prompt is required"),
  });

  return (
    <div className="bg-intro-2">
      <div className="app-container">
        <div className="app-header">
          <div className="col-lg-8 d-flex">
            <a className="icon-back" onClick={handleBackButton}><i className="fa-solid fa-angle-left"/> </a>
            <h1 className="m-0">AI Generated</h1>
          </div>
          <div className="col-lg-4 d-flex justify-content-end px-sm-2 gap-3 align-items-center">
            <button className="btn btn-credit"><img src={icCredit} className="ic-credit d-inline-flex" alt="Icon credit"/> <span
              className="d-inline-flex">Credit: 20</span></button>
            <button className="btn btn-common btn-create btn btn-primary">Create Image</button>
            <button className="btn-avatar"><img src={avatar} alt="img-avatar"/></button>
          </div>
        </div>

        <div className="app-content d-flex gap-3">
          <div className="sidebar">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({setFieldValue, values, isSubmitting, errors, touched, setSubmitting}) => (
                <Form>
                  <div className="mb-2 option-box">
                    <label className="form-label">Enter your prompt <i className="fa-solid fa-circle-info"/> </label>
                    <Field
                      className="form-control"
                      name="prompt"
                      component="textarea"
                      rows="5"
                      placeholder="Type here a detailed description of what you want to see in your artwork"
                    />
                    <ErrorMessage
                      name="prompt"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>

                  <div className="mb-2 option-box">
                    <label className="form-label">Upload original image <i className="fa-solid fa-circle-info"/></label>
                    <div className="upload-container">
                      <label className="upload-label">
                        <FormControl
                          type="file"
                          className="upload-input"
                          accept="image/*"
                          onChange={(event) => handleFileChange(event, setFieldValue)}
                        />
                        <div className="upload-box">
                          {!values.preview ? (
                            <>
                              <i className="upload-icon"/>
                              <span className="upload-text">Upload Image</span>
                            </>
                          ) : (
                            <div className="image-preview-container">
                              <img
                                src={values.preview}
                                alt="Preview"
                                className="upload-preview"
                              />
                              <button
                                className="remove-image-btn"
                                onClick={() => handleRemoveImage(setFieldValue)}
                              >
                                &times;
                              </button>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="mb-2 option-box">
                    <label className="form-label">Aspect ratio <i className="fa-solid fa-circle-info"/></label>
                    <select className="form-select">
                      {ratios?.map((ratio, index) => {
                        return (
                          <option key={index} value={ratio.id}>
                            {ratio.ratio} ({ratio.width} x {ratio.height})
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="mb-2 option-box">
                    <label className="form-label">Model <i className="fa-solid fa-circle-info"/></label>
                    <div className="model-container" onClick={setShowModelEdit}>
                      {selectedModel ? (
                        <>
                          <img
                            src={selectedModel?.images[0] ?? ""}
                            alt="Model image"
                            className="model-img"
                          />
                          <span>{selectedModel?.name}</span>
                          <span className="model-edit-btn"><i className="fa fa-angle-right"/> </span>
                        </>
                      ) : ""}
                    </div>
                  </div>

                  <div className="style-box option-box">
                    <label className="form-label">Select Style <i className="fa-solid fa-circle-info"/></label>
                    {
                      selectedStyle ? (
                        <>
                          <div className="selected-style-box d-flex align-items-center mb-4">
                            <img
                              src={selectedStyle?.images[0] ?? ""}
                              alt="Style image"
                              className="style-img me-3"
                            />
                            <div className="style-desc">
                              <span className="style-name fw-bold">{selectedStyle?.name}</span>
                              <Badge className="badge mt-1">LoRA</Badge>
                            </div>
                            <div className="justify-content-end">
                              <button className="btn delete-btn" onClick={removeStyle}>
                                <i className="fa fa-trash"/>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : ""
                    }
                    <Button className="btn-select-secondary" onClick={setShowStyle}>Select Style</Button>
                  </div>

                  <div className="btn-generate option-box">
                    <Button
                      type="submit"
                      className="btn-select"
                      disabled={isLoading || isSubmitting}
                    >
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        "Generate"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="main-content">
            {isLoading ? (
              <div className="loading-spinner no-result">
                <Spinner animation="border" variant="primary"/>
                <span>Loading...</span>
              </div>
            ) : (
              <>
                {result ? (
                  <div className="content-result d-flex flex-column">
                    <div className="m-2 d-flex w-100">
                      <img src={logoIcon} alt="logo" className="logo-icon"/>
                      <div className="m-2">
                        <h5 className="fw-bold mb-1 title">Cre8tive</h5>
                        <p className="mb-0 text-muted">{prompt}</p>
                      </div>
                    </div>
                    <div className="ps-5 gap-5 d-flex img-result">
                      {result?.map((item) => {
                        return (
                          <div className="img-item col-lg-3 col-md-3 mb-4">
                            <img src={item} alt="img result item"/>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="no-result">
                    <img src={ResultIcon} alt="result icon" className="no-result-img"/>
                    <span>Results will be displayed here</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {showModelEdit ? (
          <ModelPopup
            models={models}
            show={showModelEdit}
            hideModelEditing={uiEvents.hideModelEditing}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
        ) : ""}

        {showStyle ? (
          <StylePopup
            styles={styles}
            show={showStyle}
            hideStyleEditing={uiEvents.hideStyleEditing}
            selectedStyle={setSelectedStyle}
            setSelectedStyle={setSelectedStyle}
          />
        ) : ""}

      </div>
    </div>
  );
}

export default GenerateAiPage;