import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {fetchRatios} from "../../../store/actions/ratios";
import {fetchModels} from "../../../store/actions/models";
import {fetchStyles} from "../../../store/actions/styles";
import {getPromptList} from "../../../store/actions/artgen";
import copyIcon from "../../../assets/icons/copy.svg";
import "./recommend.scss"
import LoginPopup from "../../common/Login/Popup";

const HotRecommendations = ({ onPromptSelect }) => {
  const [loading, setLoading] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [pendingPrompt, setPendingPrompt] = useState(null);

  const dispatch = useDispatch();
  const { 
    prompts,
    ratios,
    models,
    styles 
  } = useSelector((state) => ({
    prompts: state.artGen.promptList,
    ratios: state.ratios.ratioList,
    models: state.models.modelList,
    styles: state.styles.styleList,
  }));

  const findMatchingRatio = (params) => {
    if (!params) return null;
    
    // First try to match by all criteria
    let matchedRatio = ratios?.find(ratio => 
      ratio.ratio === params.pic_ratio && 
      ratio.width === params.width && 
      ratio.height === params.height
    );

    // If no match found, try matching just by ratio
    if (!matchedRatio) {
      matchedRatio = ratios?.find(ratio => ratio.ratio === params.pic_ratio);
    }

    return matchedRatio || null;
  };

  const findMatchingModel = (modelId) => {
    return models?.find(model => model.whee_external_id === modelId);
  };

  const findMatchingStyle = (styleModelConfig) => {
    if (!styleModelConfig || !styleModelConfig[0]) return null;
    const styleModelId = styleModelConfig[0].style_model_id;
    return styles?.find(style => style.whee_external_id === styleModelId);
  };

  const formatPromptText = (text) => {
    if (!text) return '';
    // If text contains comma without space after it, add space
    const result = text.replace(/,(?!\s)/g, ', ');
    return result;
  };

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    return token && user;
  };

  const handleUsePrompt = (prompt) => {
    if (!checkAuth()) {
      setPendingPrompt(prompt);
      setShowLoginPopup(true);
      return;
    }

    // First scroll to top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // Find matching ratio, model and style
    const matchedRatio = findMatchingRatio(prompt.params);
    const matchedModel = findMatchingModel(prompt.model_id);
    const matchedStyle = findMatchingStyle(prompt.params?.style_model_config);

    // Then pass the prompt data to parent
    onPromptSelect({
      prompt: formatPromptText(prompt.prompt_en),
      model: matchedModel || null,
      style: matchedStyle || null,
      ratio: matchedRatio || null,
      enhance_params: {
        face_detection: prompt.params?.facial_detection,
        negative_prompt: formatPromptText(prompt.params?.negative_prompt_en) || '',
        face_similarity: prompt.params?.face_generation_scale,
        advanced_enable: (prompt.params?.steps > 0 || prompt.params?.temperature > 0) ?? false,
        face_similarity_enable: (prompt.params?.steps > 0 || prompt.params?.temperature > 0) ?? false,
        steps: prompt.params?.steps,
        number_of_images: prompt.params?.batch_size,
        temperature: prompt.params?.cfg_scale,
        seed: prompt.params?.seed,
        random_seed: prompt.params?.seed === -1,
      }
    });
  };

  const handleLoginSuccess = () => {
    setShowLoginPopup(false);
    if (pendingPrompt) {
      handleUsePrompt(pendingPrompt);
      setPendingPrompt(null);
    }
  };

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem("token");
    if (token) {
      // Fetch all necessary data if logged in
      dispatch(fetchRatios());
      dispatch(fetchModels());
      dispatch(fetchStyles());
    }
    // Always fetch prompt list since it's public
    dispatch(getPromptList({page: 1, items_per_page: 12}));
  }, []);

  return (
    <>
      <Container fluid className="mt-5 text-start">
        <Row className="list-prompt">
          {prompts?.map((item) => (
            <Col key={item.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="h-100 border-0">
                <Card.Img variant="top" src={item.picture_url} alt={item.title_en} />
                <div className="hover-overlay">
                  <button 
                    className="use-prompt-btn"
                    onClick={() => handleUsePrompt(item)}
                  >
                    <img src={copyIcon} alt="copy icon" />
                    Use This Prompt
                  </button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        {loading && (
          <div className="text-center my-3">
            <Spinner animation="border" />
          </div>
        )}
      </Container>

      <LoginPopup 
        show={showLoginPopup} 
        handleClose={() => {
          setShowLoginPopup(false);
          setPendingPrompt(null);
        }}
        onSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default HotRecommendations;